var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-container flex-row pb-0 pt-0"},[_c('div',{staticClass:"sidebar-container"},[_c('div',{staticClass:"menu"},[_c('ul',{staticClass:"menu-items"},[_c('li',{staticClass:"menu-title current"},[_vm._v(" tools ")]),_c('li',{staticClass:"active"},[_vm._v("CDN Cache Refresh")]),_c('li',{on:{"click":_vm.handleFriendlyURLClick}},[_vm._v("Friendly URLs")]),_c('li',{on:{"click":_vm.handleQRCodeClick}},[_vm._v("QR Codes")])])])]),(_vm.spinnerOverlay)?_c('div',{staticClass:"overlay-spinner"},[_c('svg',{staticClass:"spinner",attrs:{"viewBox":"0 0 50 50"}},[_c('circle',{staticClass:"path",attrs:{"cx":"25","cy":"25","r":"20","fill":"none","stroke-width":"5"}})])]):_c('div',{staticClass:"page"},[_c('div',{staticClass:"subpage-content"},[_c('section',[_c('div',{staticClass:"content-band"},[_c('div',[_vm._m(0),(_vm.siteAreasList != '')?_c('div',{staticClass:"site-list-not-empty"},_vm._l((_vm.siteAreasList),function(data){return _c('article',{key:data.siteName,staticClass:"site-list"},[_c('div',{staticClass:"site-name"},[_vm._v(_vm._s(data.siteDomain))]),_c('div',{staticClass:"reset-cache"},[(_vm.pleaseWait[data.siteDomain])?_c('div',{staticClass:"progress"},[_c('p',{staticClass:"updated-text"},[_vm._v("Contacting CDN")])]):(
                      _vm.progressMessage[data.siteDomain] &&
                        !_vm.pleaseWait[data.siteDomain]
                    )?_c('div',{staticClass:"progress"},[_c('div',{staticClass:"spin"},[_c('svg',{staticClass:"spinner",attrs:{"viewBox":"0 0 50 50"}},[_c('circle',{staticClass:"path",attrs:{"cx":"25","cy":"25","r":"20","fill":"none","stroke-width":"5"}})])]),_c('p',{staticClass:"updated-text"},[_vm._v("Refreshing Cache")])]):(
                      _vm.updateMessage[data.siteDomain] &&
                        !_vm.progressMessage[data.siteDomain] &&
                        !_vm.pleaseWait[data.siteDomain]
                    )?_c('div',{staticClass:"reset-wait-if"},[_vm._m(1,true),_c('button',{staticClass:"reset-wait source-sans-pro",attrs:{"disabled":""}},[_c('div',{staticClass:"font-white btn-text font-iop-blue"},[_c('div',{staticClass:"timer"},[_c('TimerCountDown',{attrs:{"initial":_vm.date,"domainName":data.siteDomain},on:{"event_child":_vm.eventChild}})],1)])])]):(_vm.errorMessage[data.siteDomain])?_c('div',{staticClass:"reset-wait-if"},[_c('p',{staticClass:"error-text"},[_vm._v(" Cache cannot be refreshed at this time. Please try again later. ")])]):(_vm.errorMessageInvalid[data.siteDomain])?_c('div',{staticClass:"reset-wait-if"},[_c('p',{staticClass:"error-text"},[_vm._v(" No CDN Cache Distribution was found for "+_vm._s(data.siteDomain)+".Think this is a mistake? Submit a ticket to IOP by emailing "),_c('a',{attrs:{"href":"mailto:CSC@Ohio.gov"}},[_vm._v("CSC@Ohio.gov")]),_vm._v(". ")])]):_c('div',{staticClass:"reset-else"},[_c('button',{staticClass:"iop-drk-blue-100 rounded-20 w300 pointer source-sans-pro",on:{"click":function($event){return _vm.cdnCache(data.siteDomain)}}},[_c('div',{staticClass:"font-white btn-text"},[_vm._v("Refresh Cache")])])])])])}),0):_c('div',{staticClass:"site-list-empty"},[_vm._m(2)])])])])]),_c('SubFooter')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-band mt-60"},[_c('h2',{staticClass:"semibold font-iop-drk-blue"},[_vm._v("CDN Cache")]),_c('p',[_vm._v(" The content delivery network or CDN refreshes cache automatically every few hours. Manually refreshing cache can help edits to published items appear in production sooner. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"updated-text"},[_c('strong',[_vm._v("Cache is refreshed.")]),_vm._v(" You can refresh again in: ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Sorry, you don't have permission to refresh cache on any websites. Think this is a mistake? Submit a ticket to IOP by emailing "),_c('a',{attrs:{"href":"mailto:CSC@Ohio.gov"}},[_vm._v("CSC@Ohio.gov")]),_vm._v(". ")])}]

export { render, staticRenderFns }