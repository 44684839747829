<template>
  <div class="content-container flex-row pb-0 pt-0">
    <div class="sidebar-container">
      <div class="menu">
        <ul class="menu-items">
          <li class="menu-title current">
            tools
          </li>
          <li class="active">CDN Cache Refresh</li>
          <li class="" @click="handleFriendlyURLClick">Friendly URLs</li>
          <li class="" @click="handleQRCodeClick">QR Codes</li>
        </ul>
      </div>
    </div>
    <!-- SPINNER OVERLAY -->
    <div class="overlay-spinner" v-if="spinnerOverlay">
      <svg class="spinner" viewBox="0 0 50 50">
        <circle
          class="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="5"
        ></circle>
      </svg>
    </div>
    <!-- SPINNER OVERLAY -->
    <div class="page" v-else>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 margin-bottom-md"> -->
              <div class="content-band mt-60">
                <h2 class="semibold font-iop-drk-blue">CDN Cache</h2>
                <p>
                  The content delivery network or CDN refreshes cache
                  automatically every few hours. Manually refreshing cache can
                  help edits to published items appear in production sooner.
                </p>
              </div>
              <div class="site-list-not-empty" v-if="siteAreasList != ''">
                <article
                  class="site-list"
                  v-for="data in siteAreasList"
                  v-bind:key="data.siteName"
                >
                  <div class="site-name">{{ data.siteDomain }}</div>
                  <div class="reset-cache">
                    <div class="progress" v-if="pleaseWait[data.siteDomain]">
                      <p class="updated-text">Contacting CDN</p>
                    </div>
                    <div
                      class="progress"
                      v-else-if="
                        progressMessage[data.siteDomain] &&
                          !pleaseWait[data.siteDomain]
                      "
                    >
                      <div class="spin">
                        <svg class="spinner" viewBox="0 0 50 50">
                          <circle
                            class="path"
                            cx="25"
                            cy="25"
                            r="20"
                            fill="none"
                            stroke-width="5"
                          ></circle>
                        </svg>
                      </div>
                      <p class="updated-text">Refreshing Cache</p>
                    </div>
                    <div
                      class="reset-wait-if"
                      v-else-if="
                        updateMessage[data.siteDomain] &&
                          !progressMessage[data.siteDomain] &&
                          !pleaseWait[data.siteDomain]
                      "
                    >
                      <p class="updated-text">
                        <strong>Cache is refreshed.</strong> You can refresh
                        again in:
                      </p>
                      <button class="reset-wait source-sans-pro" disabled>
                        <div class="font-white btn-text font-iop-blue">
                          <div class="timer">
                          <TimerCountDown
                            :initial="date"
                            :domainName="data.siteDomain"
                            v-on:event_child="eventChild"
                          />
                          </div>
                        </div>
                      </button>
                    </div>
                    <div
                      class="reset-wait-if"
                      v-else-if="errorMessage[data.siteDomain]"
                    >
                      <p class="error-text">
                        Cache cannot be refreshed at this time. Please try again
                        later.
                      </p>
                    </div>
                    <div
                      class="reset-wait-if"
                      v-else-if="errorMessageInvalid[data.siteDomain]"
                    >
                      <p class="error-text">
                        No CDN Cache Distribution was found for
                        {{ data.siteDomain }}.Think this is a mistake? Submit a
                        ticket to IOP by emailing
                        <a href="mailto:CSC@Ohio.gov">CSC@Ohio.gov</a>.
                      </p>
                    </div>
                    <div class="reset-else" v-else>
                      <button
                        class="iop-drk-blue-100 rounded-20 w300 pointer source-sans-pro"
                        @click="cdnCache(data.siteDomain)"
                      >
                        <div class="font-white btn-text">Refresh Cache</div>
                      </button>
                    </div>
                  </div>
                </article>
              </div>
              <div class="site-list-empty" v-else>
                <p>
                  Sorry, you don't have permission to refresh cache on any
                  websites. Think this is a mistake? Submit a ticket to IOP by
                  emailing <a href="mailto:CSC@Ohio.gov">CSC@Ohio.gov</a>.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <SubFooter />
    </div>
  </div>
</template>

<script>
// import TopLevelLeftNav from "./TopLevelLeftNav";
import axios from "axios";
import Vue from "vue";
import * as moment from "moment";
import SubFooter from "./SubFooter";
import TimerCountDown from "./TimerCountDown";
//import list from "../assets/data/fakeAPI.json";
import { AuthConfig } from "../auth/auth.config.js";
export default {
  data: function() {
    return {
      spinnerOverlay: true,
      dataArray: [],
      siteAreasList: [],
      responseData: [],
      updateMessage: [],
      errorMessage: [],
      errorMessageInvalid: [],
      progressMessage: [],
      pleaseWait: [],
      date: moment(60 * 5 * 1000),
     // showQRCode: false
    };
  },
  components: {
    // TopLevelLeftNav,
    TimerCountDown,
    SubFooter,
  },
/*   created: function(){
    if (
      window.location.href.includes("localhost") ||
      window.location.href.includes("stg")
    ) {
      this.showQRCode = true
    }
  }, */
  mounted() {
    this.loadUserSiteData();
  },
  methods: {
    handleFriendlyURLClick: function() {
      this.$router.push("/tools/friendly-urls");
    },
    handleQRCodeClick: function () {
      this.$router.push("/tools/qr-code")
    },
    eventChild: function(domainName) {
      Vue.set(this.updateMessage, domainName, 0);
    },
    loadUserSiteData: function() {
      //To load the data from session
      var userSiteData = JSON.parse(sessionStorage.getItem('userRoles'));
      if (userSiteData === null) {
        return setTimeout(() => {
          this.loadUserSiteData();
        }, 5000)
      } else {
        this.getUserData(userSiteData)
      }
    },
    getUserData:function(userSiteData) {
     this.dataArray = userSiteData;
     this.spinnerOverlay = false;
     this.changeOdotDomain(this.dataArray);

      if (this.dataArray.length > 0) {
        this.siteAreasList = this.dataArray
          .filter(
            (a) =>
              a.siteDomain != "" &&
              (a.userRole == "Manager" ||
                a.userRole == "Reviewer" ||
                a.userRole == "Editor" ||
                a.userRole == "Administrator")
          )
          .sort((a, b) => a.siteDomain.localeCompare(b.siteDomain));
      } else {
        this.siteAreasList = "";
      }
    },
    changeOdotDomain(dataArray) {
      //Function to remove "www." from ODOT siteDomain.
      for (var i=0; i<dataArray.length; i++){
        if (dataArray[i].siteName === "odot") {
          if (dataArray[i].siteDomain.includes("www")) {
            var newSiteDomain = dataArray[i].siteDomain.replace('www.', '');
            return dataArray[i].siteDomain = newSiteDomain;
          }
        }
      }
    },
    cdnCache: async function(domainName) {
      var currentDomainName = domainName;
      // Implement Cache Reset only if domain name is not NULL
      if (currentDomainName != "") {
        Vue.set(this.pleaseWait, domainName, 1);
        console.log("Current Domain", currentDomainName);
        var responseStatus = await this.cacheResetCdnGet(currentDomainName);
        // If response Status is success then display progress of Cache Reset
        if (responseStatus === 201) {
          Vue.set(this.pleaseWait, domainName, 0);
          Vue.set(this.progressMessage, domainName, 1);
          setTimeout(() => {
            Vue.set(this.progressMessage, domainName, 0);
          }, 3000);
          Vue.set(this.updateMessage, domainName, 1);
        }
        // Else if response status is 404
        else if (responseStatus === 404) {
          Vue.set(this.pleaseWait, domainName, 0);
          Vue.set(this.errorMessageInvalid, domainName, 1);
          setTimeout(() => {
            Vue.set(this.errorMessageInvalid, domainName, 0);
          }, 300000);
        }
        // Else in case any error
        else {
          Vue.set(this.pleaseWait, domainName, 0);
          Vue.set(this.errorMessage, domainName, 1);
          setTimeout(() => {
            Vue.set(this.errorMessage, domainName, 0);
          }, 3000);
        }
      }
      // If domain name is null then error out
      else {
        Vue.set(this.errorMessage, domainName, 1);
        setTimeout(() => {
          Vue.set(this.errorMessage, domainName, 0);
        }, 3000);
      }
    },
    cacheResetCdnGet: async (currentDomainName) => {
      let idToken = sessionStorage.getItem("userIDtoken");
      let tokenExpired = sessionStorage.getItem("tokenExpiresIn");
      //let apiPath = sessionStorage.getItem("apiPath");
      let apiPath = AuthConfig.getCurrentAuthConfig().apiGateWay;
      if (Date.now() >= tokenExpired * 1000) {
        let ohidURL = sessionStorage.getItem("ohidUrl");
        window.open(ohidURL + "/pkmslogout", "_self").focus();
      } else {
        let config = {
          headers: {
            Authorization: idToken,
          },
        };
        try {
          const response = await axios.get(
            apiPath + "/cdn/invalidate/" + currentDomainName,
            config
          );
          if (response.status == 201) {
            // for debugging only
            console.log(
              "Cache Reset API- Invalidation in progress",
              response.data
            );
            return response.status;
          }
          return response.status;
        } catch (err) {
          if (err.response) {
            console.log(
              "Cache Reset API -Something went wrong",
              err.response.status
            );
            return err.response.status;
          } else {
            console.log("CORS ERROR", err);
            return err;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.timer{
  color: #004393 !important;
}
.subpage-content {
  height: unset;
}
.header-description {
  margin: 0 0 20px 0;
  text-align: left;
}
.site-list {
  width: 921px;
  height: 70px;
  background-color: #e7e7e7;
  display: flex;
  margin: 10px 20px;
  border-radius: 4px;
  align-items: center;
}
.site-name {
  font-weight: normal;
  font-size: 16px;
  padding: 20px;
  line-height: 28px;
  color: #000000;
  margin-left: 18px;
}
.site-list-empty {
  margin: 10px 20px;
}
.reset-cache {
  margin: auto 10px auto auto;
}
.reset {
  padding: 8px 8px;
  width: 150px;
  color: white;
  background-color: #5c5a5b;
  text-align: center;
  border-radius: 20px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  line-height: 28px;
}
.reset-wait-if {
  display: flex;
}
.reset-wait {
  color: var(--iop-drk-blue-800);
  background-color: white;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  border-radius: 20px;
  width: 120px !important;
}
p.updated-text {
  margin-right: 10px;
  color: black;
  margin-right: 10px;
  font-size: 16px;
  line-height: 10px;
  white-space: pre;
}

p.error-text {
  color: red;
  margin-right: 10px;
  font-weight: bold;
}
.progress {
  display: flex;
  margin-top: 8px;
}
.overlay-spinner {
  background-color: rgba(0,0,0,0.5);
  color: #666666;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
  opacity: .80;
}
/* //////////////////////
    Spinner
////////////////////// */
.spin {
  position: relative;
  margin-top: 15px;
  margin-right: 10px;
}
.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #f6f6f6;
}
.spinner .path {
  stroke: #b8b8b8;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
