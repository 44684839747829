<template>
  <div>{{ currentTime }}</div>
</template>

<script>
import * as moment from "moment";
export default {
  props: ["initial", "domainName"],
  data() {
    return {
      date: this.initial,
      message: this.domainName,
    };
  },
  computed: {
    currentTime() {
      return this.date.format("mm:ss");
    },
  },
  methods: {
    emit: function () {
      this.$emit("event_child", this.message);
    },
    onInterval() {
      this.date = moment(this.date.subtract(1, "seconds"));
      if (this.date.diff(moment(0)) === 0) {
        this.emit();
      }
    },
  },
  mounted() {
    setInterval(this.onInterval, 1000);
  },
};
</script>